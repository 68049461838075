import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

const firestoreInitializeInfos = {
    apiKey: "AIzaSyCmmMlxHlHbagocYN6w9VYF-gtDT6u5YL0",
    authDomain: "lazycl.firebaseapp.com",
    projectId: "lazycl",
    storageBucket: "lazycl.appspot.com",
    messagingSenderId: "1064002362545",
    appId: "1:1064002362545:web:6c2c837aba303a16805690",
    measurementId: "G-R1KTGX91T0",
};

const app = initializeApp(firestoreInitializeInfos);

const functions = getFunctions(app);

export const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession')
export const getLettersText = httpsCallable(functions, 'getLettersText')
export const getOpenAIGenerationKey = httpsCallable(functions, 'getOpenAIGenerationKey')
export const getFirestoreInitializeInfos = httpsCallable(functions, 'getFirestoreInitializeInfos')