import { Configuration, OpenAIApi } from "openai";
import { getOpenAIGenerationKey } from '../firestore/firestore';

async function getOpenAI() {
    const openai = await getOpenAIGenerationKey()
        .then((res) => {
            const configuration = new Configuration({ apiKey: res.data });

            const openai = new OpenAIApi(configuration);

            return openai;
        })  
    return openai; 
}

export const generateLetter = async (formData) => {
    const openai = await getOpenAI();
    if(openai === "error"){
        return "error"
    } else {
        let prompt = "Create a cover letter for a " + formData.jobTitle + " , the company is " + formData.companyName + " and the address is " + formData.companyAddress + ", my employer name is " + formData.employerName + ", my name is " + formData.userName + " and I have " + formData.jobExperience + " years of experience " + " , one of my biggest quality at this job is " + formData.jobQualities + ", the description for the job is : " + formData.jobDescription;
        const res = await openai.createCompletion(
            {
                model: "text-davinci-003",
                prompt: prompt,
                n: 3,
                max_tokens: 2000,
            },
        );
        return res.data.choices;
    }
};