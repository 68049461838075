import React from "react";
import './PaymentError.css'

const PaymentError = () => {

    const returnHome = () => {
        window.location.href = "http://lazycl.web.app"
    };

    return(
        <div className="paymentError">
            <div className="paymentErrorContent">
                <button onClick={returnHome} className="button-close">&times;</button>
                <h2>Your payment has been refused</h2>
                <div class="wrapperCancel"> 
                    <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                        <circle class="checkmark_circle" cx="26" cy="26" r="25" fill="none"/>
                        <path class="checkmark_check" fill="none" d="M14.1 14.1l23.8 23.8 m0,-23.8 l-23.8,23.8"/>
                    </svg>
                </div>
            </div>
        </div>
    );
}

export default PaymentError;