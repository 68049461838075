import React, { useState } from 'react';
import './PromotionStrip.css';

const PromotionStrip = () => {
  const [showStrip, setShowStrip] = useState(true);

  const handleDismiss = () => {
    setShowStrip(false);
  };

  if (!showStrip) {
    return null;
  }

  return (
    <div className="promotion-strip">
      <p>
        Launch promotion: <span>$1.99</span> for 3 versions of the cover letter.
      </p>
      <button onClick={handleDismiss} className="dismiss-button">&times;</button>
    </div>
  );
};

export default PromotionStrip;
