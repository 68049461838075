import React, {useState, useEffect} from 'react';
import './GenerateLetterModal.css';
import { generateLetter } from '../openai/openai';
import buttonloader from '../images/button-loader.gif'
import { createCheckoutSession } from '../firestore/firestore'
import loading from '../images/loading.gif'

const GenerateLetterModal = () => { 
    const [modalVisible, setModalVisible] = useState(false);
    const [step, setStep] = useState(0);
    const [errorMessageVisible, setErrorMessageVisible] = useState(false);
    const [generationError, setGenerationError] = useState(false);
    const [letters, setLetters] = useState([]);
    const [formData, setFormData] = useState({
        companyAddress: '',
        companyName: '',
        employerName: '',
        jobTitle: '',
        jobDescription: '',
        jobExperience: '',
        jobQualities: '',
        userName: '',
        userAddress: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const showModal = () => {
        document.body.classList.add('no-scroll');
        setModalVisible(true);
    };

    const closeModal = () => {
        document.body.classList.remove('no-scroll');
        setModalVisible(false);
        resetFormData();
    };

    const resetFormData = () => {
        setFormData({
            companyAddress: '',
            companyName: '',
            employerName: '',
            jobTitle: '',
            jobDescription: '',
            jobExperience: '',
            jobQualities: '',
            userName: '',
            userAddress: '',
        })
        setStep(0);
        setErrorMessageVisible(false);
    }

    const handleInputChange = (event) => {
        const {id, value} = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [id]: value,
        }));
    };

    const handlePrevious = () => {
        if(step >= 1 && step <= 4){
            setStep(step - 1);
            setErrorMessageVisible(false);
        }
    }

    async function handleNext(){
        switch (step) {
            case 0:
                if(formData.companyAddress && formData.companyName && formData.employerName){
                    setStep(step + 1);
                    setErrorMessageVisible(false);
                }
                else
                    setErrorMessageVisible(true);
                break;
            case 1:
                if(formData.companyAddress && formData.companyName && formData.employerName && formData.userName && formData.userAddress){
                    setStep(step + 1);
                    setErrorMessageVisible(false);
                }
                else
                    setErrorMessageVisible(true);
                break;
            case 2:
                if(formData.companyAddress && formData.companyName && formData.employerName && formData.userName && formData.userAddress && formData.jobTitle && formData.jobDescription){
                    setStep(step + 1);
                    setErrorMessageVisible(false);
                }
                else
                    setErrorMessageVisible(true);
                break;
            case 3:
                if(formData.companyAddress && formData.companyName && formData.employerName && formData.userName && formData.userAddress && formData.jobTitle && formData.jobDescription && formData.jobExperience && formData.jobQualities){
                    setStep(step + 1);
                    setErrorMessageVisible(false);
                    const lettersRaw = await generateLetter(formData).then(res => {
                        return res;
                    });
                    if(lettersRaw === "error"){
                        setGenerationError(true);
                    } else {
                        setLetters(lettersRaw);
                    }    
                }
                else
                    setErrorMessageVisible(true);
                break;
            case 4:
                handleCheckout();
                checkoutLoading();
            default:
                break;
        }
    };

    async function checkout() {
        if(letters.length > 0){
            createCheckoutSession({
                body: JSON.stringify({
                    formData: formData,
                    letters: letters,
                })
            })
            .then(response => {
                if(response.data) {
                    window.location.href = response.data
                }
            })
            .catch((error) => {
                console.log(error);
            });
        }
    }

    useEffect(() => {
        if(isLoading){
            setTimeout(() => {
                setShowLoader(true);
            }, 500)
        }
    }, [isLoading])

    async function handleCheckout() {
        if(!isLoading){
            await checkout();
        }
    }

    function checkoutLoading() {
        if(isLoading){
            setIsLoading(false);
        }
        else {
            setIsLoading(true);
            setShowLoader(false);
        }
    }

    return (
        <div className="generateLetterModal">
            <div className='generateLetterMainButton'>
                <button className="main-button" onClick={showModal}>
                    Try it out ! <span>🤖🛠️</span>
                </button>
            </div>
            {modalVisible && (
                <div className="generate-modal">
                    <div className="generate-modal-content">
                        <div className="loading-bar-bg">
                            <div className="loading-bar" style={{ width: `${(step / 4) * 100}%` }} />
                        </div>
                        <button onClick={closeModal} className="button-close">&times;</button>
                        { errorMessageVisible && <p className='errorMessage'>Please make sure you have filled in all the information requested !</p> }
                        { step === 0 && (
                            <>
                                <div className='generate-modal-titles'>
                                    <h2>Step 1/4</h2>
                                    <h2>Company informations</h2>
                                </div>
                                <div className="form-fields">
                                    <label htmlFor="companyAddress">Company address</label>
                                    <input
                                        type="text"
                                        id="companyAddress"
                                        value={formData.companyAddress}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                    <label htmlFor="companyName">Company name</label>
                                    <input
                                        type="text"
                                        id="companyName"
                                        value={formData.companyName}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                    <label htmlFor="employerName">Employer name</label>
                                    <input
                                        type="text"
                                        id="employerName"
                                        value={formData.employerName}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>
                            </>
                        )}
                        {step === 1 && (
                            <>
                                <div className='generate-modal-titles'>
                                    <h2>Step 2/4</h2>
                                    <h2>Your informations</h2>
                                </div>
                                <div className="form-fields">
                                    <label htmlFor="userName">Your name</label>
                                    <input
                                        type="text"
                                        id="userName"
                                        value={formData.userName}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                    <label htmlFor="userAddress">Your address</label>
                                    <input
                                        type="text"
                                        id="userAddress"
                                        value={formData.userAddress}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>   
                            </>
                        )}
                        {step === 2 && (
                            <>
                                <div className='generate-modal-titles'>
                                    <h2>Step 3/4</h2>
                                    <h2>Job informations</h2>
                                </div>
                                <div className="form-fields">
                                    <label htmlFor="jobTitle">Job title</label>
                                    <input
                                        type="text"
                                        id="jobTitle"
                                        value={formData.jobTitle}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                    <label htmlFor="jobDescription">Job description</label>
                                    <textarea
                                        id="jobDescription"
                                        value={formData.jobDescription}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>        
                            </>              
                        )}
                        {step === 3 && (
                            <>
                                <div className='generate-modal-titles'>
                                    <h2>Step 4/4</h2>
                                    <h2>Job experiences</h2>
                                </div>
                                <div className="form-fields">
                                    <label htmlFor="jobExperience">Years of experience</label>
                                    <input
                                        type="text"
                                        id="jobExperience"
                                        value={formData.jobExperience}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                    <label htmlFor="jobDescription">A quality you have at this job</label>
                                    <input
                                        type="text"
                                        id="jobQualities"
                                        value={formData.jobQualities}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </div>        
                            </>              
                        )}
                        { 
                            step <= 3 && <button onClick={handleNext} className="standard-button button-next">
                                <i className="fa-solid fa-arrow-right"></i>
                            </button>
                        }
                        { 
                            step >= 1 && step <= 3 && <button onClick={handlePrevious} className="standard-button button-previous">
                                <i className="fa-solid fa-arrow-left"></i>
                            </button>
                        }
                        {
                            step === 4 && <div className="generateLetter">
                                { letters.length === 0 && <div>
                                    <h2>Please wait for generation</h2>
                                    <img src={buttonloader} />
                                    </div> }
                                { letters.length > 0 && <button className={isLoading ? "standard-button pay-button loading" : "standard-button pay-button"} onClick={handleNext}>
                                    { isLoading && !errorMessageVisible ? 
                                        <>
                                            { showLoader && <img src={buttonloader} className="button-loader-style"/> }
                                        </>
                                        :
                                        <>
                                            <i className="fa-solid fa-lock"></i>
                                            <div className="separation-bar"></div>
                                            Go to checkout
                                        </>
                                    }
                                </button> }
                                {
                                    generationError && <h2>Generation failed, please try again ...</h2>
                                }
                            </div>
                        }
                    </div>
                </div>
            )}
        </div>
    )
}

export default GenerateLetterModal;