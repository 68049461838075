import React, { useState, useRef, useEffect } from 'react';
import './HomePage.css'
import coverletterdisplay1 from '../images/coverletterdisplay1.jpg'
import coverletterdisplay2 from '../images/coverletterdisplay2.jpg'
import coverletterdisplay3 from '../images/coverletterdisplay3.jpg'
import easytouse from '../images/easy-to-use.png'
import inequality from '../images/inequality.png'
import technology from '../images/technology.png'
import openai from '../images/OpenAI_Logo.svg'
import Footer from '../Footer/Footer';
import GenerateLetterModal from '../GenerateLetterModal/GenerateLetterModal';
import PromotionStrip from '../PromotionStrip/PromotionStrip';

function HomePage() {

  // Declare a state variable to store the modal visibility
  const [modalVisible, setModalVisible] = useState(false);
  const [clDisplay, setClDisplay] = useState(0)

  // Declare a ref for the modal element
  const modalRef = useRef(null);

  // Define a function to show the modal
  const showModal = (num) => {
    document.body.classList.add('no-scroll');
    setClDisplay(num);
    setModalVisible(true);
  };

  // Define a function to hide the modal
  const hideModal = () => {
    document.body.classList.remove('no-scroll');
    setModalVisible(false);
  };

  // Add a click event listener to the document to check if the modal was clicked
  useEffect(() => {
    const handleClick = (event) => {
      // Check if the target of the event is the modal element
      if (event.target.id === "modal") {
        // If it is, hide the modal
        hideModal();
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return (  
    <div>
      <PromotionStrip />

      <div className="homepage">

        <h1>LazyCL</h1>

        <h2>Get a professional cover letter in minutes with our AI-powered tool.</h2> 

        <div className="icon-div">
            <div className="icon-container">
                <img src={easytouse} className="icon"/>
                <h3>Easy</h3>
                <p className="icon-text">Our cover letters make the job application process a breeze, with a user-friendly website that streamlines the customization and purchase process.</p>
            </div>
            <div className="icon-container">
                <img src={inequality} className="icon"/>
                <h3>Unique</h3>
                <p className="icon-text">With every generation, our cover letters become uniquely tailored to your needs, thanks to the power of artificial intelligence.</p>
            </div>
            <div className="icon-container">
                <img src={technology} className="icon"/>
                <h3>AI-Powered</h3>
                <p className="icon-text">Our AI-powered cover letter generator uses advanced algorithms to create highly personalized documents that stand out to potential employers.</p>
            </div>
        </div>

        <GenerateLetterModal />

        <div className="resume-pictures-bg">
          <h2>Some AI-generated examples</h2>
          <div className="resume-pictures">
            <div className="resume-picture">
              <picture> <img src={coverletterdisplay1} /> </picture>
              <button className='button-preview' onClick={() => showModal(1)}>View</button>   
              <p>Manager at Apple Inc.</p>
            </div>
            <div className="resume-picture">
              <picture> <img src={coverletterdisplay2} /> </picture>
              <button className='button-preview' onClick={() => showModal(2)}>View</button>
              <p>Online Orderfilling and Delivery at Walmart</p>
            </div>
            <div className="resume-picture">
              <picture> <img src={coverletterdisplay3} /> </picture>
              <button className='button-preview' onClick={() => showModal(3)}>View</button>
              <p>Duties Coordinator at FedEx Express</p>
            </div>
          </div>
        </div>

        <div className="card-container">
          <div className='card'>
            <img src={openai} className="openAIImg"/>
            <p>
              Aren't you tired of spending hours writing and editing your cover letters, only to end up with something that's mediocre at best? We've got the solution for you!
            </p>
            <p>
              Our state-of-the-art AI technology allows you to generate a completely unique and professional cover letter in just minutes. That's right - no more tedious writing and editing, no more settling for something that's just "okay". With Resume AI, you'll get a cover letter that will truly make you stand out from the competition and increase your chances of getting hired. Plus, our AI is fast and efficient, so you can focus on other important aspects of your job search.
            </p>
            <p>
              So why wait? Take the first step towards your dream job and get started with Resume AI today!
            </p>
          </div>
        </div>

        {/* Render the modal if it is visible */}
        {modalVisible && (
          <div id="modal" className="modal" ref={modalRef}>
            {
              clDisplay === 1 && <img src={coverletterdisplay1} alt="Resume written by AI" />
            }
            {
              clDisplay === 2 && <img src={coverletterdisplay2} alt="Resume written by AI" />
            }
            {
              clDisplay === 3 && <img src={coverletterdisplay3} alt="Resume written by AI" />
            }
          </div>
        )}

      </div>
      <Footer/> 
    </div>
  );
}

export default HomePage;
