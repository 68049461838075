import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <div className="footer">
      <div className='icons'>
        <a href="https://twitter.com/cge_dev" target="_blank"><i className="fa-brands fa-square-twitter fa-2xl"></i></a>
        <a href="mailto:cge.devpro@gmail.com" target="_blank"><i className="fa-solid fa-square-envelope fa-2xl"></i></a>
      </div>
      <img src="/logo.png" alt="Logo" />
    </div>
  );
}

export default Footer;
