import './App.css';
import HomePage from './HomePage/HomePage';
import PaymentSuccess from './PaymentSuccess/PaymentSuccess';
import PaymentError from './PaymentError/PaymentError';
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {

  return (
    <div className="app">      
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage/>} />
          <Route path="/success" element={<PaymentSuccess />} />
          <Route path="/cancel" element={<PaymentError />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
