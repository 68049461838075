import React, {useState, useEffect} from "react";
import './PaymentSuccess.css'
import jsPDF from "jspdf";
import { getLettersText } from "../firestore/firestore";

const PaymentSuccess = () => {

    const token = window.location.href.split('?id=').pop()
    const [hasDL, setHasDL] = useState(false);
    const [lettersRawText, setLettersRawText] = useState();

    useEffect(() => {
        if(lettersRawText){
            createPDF(lettersRawText.data)
        }
    }, [lettersRawText])

    const returnHome = () => {
        window.location.href = "http://lazycl.web.app"
    };

    async function sendLetters() {
        getLettersText({
            body: JSON.stringify({
                id: token
            })
        }).then(response => setLettersRawText(response))
    }

    function createPDF(data) {
        const formData = data.formData;
        let i = 1;
        let docs = {};
        for (var letter of data.letters){
            const doc = new jsPDF();
            doc.setFontSize(26)
            doc.text(formData.userName, 10, 20);
            doc.setFontSize(18)
            doc.text(formData.userAddress, 10, 30);
            doc.setFontSize(26)
            doc.text(formData.companyName, 200, 50, {
                align: 'right'
            });
            doc.setFontSize(18)
            doc.text(formData.companyAddress, 200, 60, {
                align: 'right'
            });
            let text = doc.splitTextToSize(letter.text, 190);
            doc.text(text, 10, 70)
            doc.save(i + '-' + token + '.pdf');
            i++;
        }
        setHasDL(true);
    }

    function zipFiles(pdfs) {
        const zip = require("jszip")();
        for(let i = 0; i < pdfs.length; i++) {
            //Zip files with name
            zip.file(pdfs[i+1].name, pdfs[i+1].file);
        }
        zip.generateAsync({type: "blob"}).then(content => {
            //saveAs(content, "CoverLetters.zip");
        })
    }

    return(
        <div className="paymentSuccess">
            <div className="paymentSuccessContent">
                {hasDL && <button onClick={returnHome} className="button-close">&times;</button>}
                <h2>Your payment has been accepted</h2>
                <div className="wrapperSuccess"> 
                    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                        <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                        <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                    </svg>
                </div>
                <button className="standard-button dl-button" onClick={() => sendLetters()}>Click here to download the cover letters</button>
            </div>
        </div>
    );
}

export default PaymentSuccess;